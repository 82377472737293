// Colours
$primary:         #DDDDDD;
$secondary:       #fbfbfb;

$red:         #bd5d5d;
$orange: 		#ceae70;
$dark:          #404040;
$accent:        #ceae70;
$dark-gray:       #60676d;
$light:         #EEEEEE;
$accent-a:        #60676d;
$accent-b:        #d5d5d5;
$accent-c:        #516773;

$status-waiting:    #AAAAAA;
$status-queued:     #e4a21d;
$status-confirmed:    #859a77;
$status-declined:   #7f98a5;

$pad:         1em;
$gutter:        0.75em;
$spacing:       0em;
$opacity:       0.2;

@import 'libs/_mixins';
@import 'libs/_modal';

body {
	background: #FFFFFF;
	color: $dark !important;
	padding-bottom: 4em;

	#main {
		h1,h2,h3,h4,h5 {
			color: $dark;
			font-weight: 300;
			text-transform: none;
			letter-spacing: $spacing;
			&:after {
				height: 0;
			}
		}
		h2 {
			color: $dark-gray;
			font-size: 1.5em;
			margin: 0.5em 0;
			font-weight: bold;
		}
	}
	i.fa {
		//margin-right: $gutter;
	}
	// Anchors
	a {
		color: $accent-c;
		text-decoration: none;
		&:hover {
			color: $accent-b !important;
			text-decoration: underline;
		}
	}
	.table {
		overflow-x: auto;
		form {
			margin: 0;
		}
	}
	table.picker__table {
		td {
			&:nth-of-type(2) {
				padding-left: 0;
			}
		}
	}
	table.dataTable thead th, 
	table.dataTable thead td,
	table.dataTable.no-footer {
		border: none;
	}
	table {
		overflow-x: auto;
		white-space: nowrap;
		h3 {
			margin-bottom: $gutter;
		}
		th {
			color: $accent-a;
			cursor: pointer;
			&:active {
				color: $accent-b;
			}
			&:nth-of-type(1) {
					width: 50px;
			}
			&:nth-of-type(2) {
				// padding-left: 2em;
			}
		}
		tr {
			border-color: rgba(144,144,144,0.1);
			&.odd {
				background: $secondary !important;
			}
		}
		th, td {
			padding: $pad / 2;
		}
		td {
			&:nth-of-type(1) {
				color: $accent;
				text-align: center;
				width: 35px;
				font-weight: bold;
			}
			&:nth-of-type(2) {
				// padding-left: 2em;
			}
		}
		thead {
			border-bottom: none;
		}
	}
	.button.special {
		color: #FFFFFF !important;
		background-color: $dark;
		padding: 0 1em;
		// box-shadow: 1px 1px 2px 0 rgba(0,0,0,0.5);
		&.add, &.action {
			background: rgba($accent-a,1);
			&:hover {
				// color: $dark !important;
				// background: rgba($accent-b,1);
			}
		}
		&.active {
			background: $red !important;
		}
		&:hover {
			// color: $dark !important;
			background: $accent-a;
		}
	}
	input.button.delete {
		background: $red;
	}
	ul.actions {
		margin: 0;
	}
	.btn-fix {
		position: fixed;
		bottom: 0.5em; right: 0.5em;
		z-index: 9;
	}
	.accent {
		&.highlight {
			color: $orange !important;
		}
		color: $accent !important;
		font-weight: bold;
		&.title {
			font-weight: 800;
			text-transform: uppercase;
		}
	}
	.accent-b {
		color: $accent-b !important;
	}
	.accent-c {
		color: $accent-c !important;
	}
	strong {
		color: rgba($accent-a, 0.5);
		font-weight: bold;
	}

	// TABLE STYLES
	.user-status {
		&.blocks {
			span.user {
				display: inline;
				padding: $pad/3 0 $pad/3 $pad/3;
				margin-right: $pad/3;
			}
			span.payment {
				display: inline-block;
				border-left: 2px solid rgba(255,255,255,0.2);
				padding: 0 $pad/3;
			}
		}
	}

	// Remove first column width limit
	.table.plain {
		th, td {
			&:nth-of-type(1) {
				width: auto;
			}
		}
	}

	//Forms
	// form {
		.currency {
			position: relative;
			input {
				padding-left: 1.5em;
			}
			.currency-symbol {
				&.align {
					top: 0.45em;
					left: 1em;
					position: absolute;
				}
			}
		}
		select {
			option {
				color: $dark-gray !important;
				background: white !important;
				-webkit-appearance: none;
			}
		}
		input[type="radio"],
		input[type="checkbox"] + label:before, input[type="radio"] + label:before {
			border: none !important; 
		    line-height: 1.8em;
		}
		input[type], select, textarea {
			font-size: 1.1em;
			// color: $accent-a !important;
			background: #FFFFFF;
			border: 2px solid #EEEEEE;
			// padding: 0 1em;
		}
		input[type="radio"] {
			opacity: 1;
		}
		input[type="checkbox"]:checked+label:before, 
		input[type="radio"]:checked+label:before,
		input[type="checkbox"]+label:before, 
		input[type="radio"]+label:before,  {
			// background-color: transparent;
			// border-color: transparent;
			// color: $dark-gray;
		}
		input[type="radio"]+label {
			padding-left: 2em;
			padding-right: 0.2em;
		}
		input:focus {
				outline:none !important;
		}
		select > option {
			// color: $light !important;
		}
		label {
			color: $accent-a !important;
			font-weight: bold;
			font-size: 1.2em;
			margin: 0;
		}
		
		.dataTables_filter {
			label {
				font-size: 1em;
			}
		}
		.dataTables_wrapper .dataTables_filter {
		    float: left;
		    text-align: right;
		    margin-bottom: 1em;
		}
		.dataTables_wrapper .dataTables_filter input {
			padding-left: 0.5em;
		}
		input.reply.button {
			font-size: 0.5em;
			height: 2.25em;
			line-height: 2.25em;
			padding: 0 1em;
		}
	// }
	
	.help-block {
		strong {
			color: $red;
		}
	}

	ul li form {
		margin: 0;
	}

	// Global
	.fill {
		width: 100%;
	}
	.align {
		&.left {
			text-align: left;
		}
		&.right {
			text-align: right;
		}
	}

	.font-x {
		font-size: 1.2em;
	}

	// Invoice
	.send_invoice {
		&.active {
			color: $status-confirmed;
		}
		&.inactive {
			color: rgba($status-waiting, 0.3);
		}
	}

	// User
	span {
		&.skill, &.role {
			background: $dark;
		}
		&.skill, &.user, &.role{
			color: $accent;
			padding: 0.2em 0.5em;
			@include border-radius(3px);
		}
		&.status_, &.status_0, &.status_1, &.status_2, &.status_3, &.status_queued {
			color: $light;
			display: block;
			text-align: center;
			padding: 0 0.5em;
			@include border-radius(3px);
		} 
		&.status_, &.status_0 {
			color: $light;
			background: $status-waiting;
		}
		&.status_1 {
			background: $status-confirmed;
		}
		&.status_2 {
			background: $status-declined;
		}
		&.status_3 {
			color: #FFFFFF;
			background: rgba($status-waiting, 0.3);
		}
		&.status_queued {
			color: $light;
			background: $status-queued;
		}
	}
	
	#all-events, #event { 
		.status_3 {
			background: $status-declined;
		}
	}
	#archive_heading h1 {
		font-size: 1.8em;
		text-transform: uppercase;
	}
	#archive_controls {
		text-align: right;
	}
	@media(max-width: 480px) {
		#archive_controls {
			text-align: left;
		}
	}
	@media(max-width: 736px) {
		.dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate {
			text-align: left;
		}
		div#home_length {
			float: left;
		}
		.dataTables_wrapper .dataTables_length, 
		.dataTables_wrapper .dataTables_filter {
			text-align: left;
		}
		.dataTables_wrapper .dataTables_filter {
			border-bottom: 5px solid;
			margin-bottom: 1em;
			padding-bottom: 1em;
		}
	}
	
	.align {
		&.right {
			float: right;
		}
		&.left {
			float: left;
		}
	}

	.info-block {
		@include border-radius(3px);
		background: $secondary;
		padding: $pad;
		font-size: 1.05em;
		ul {
			margin: 0;
		}
		strong {
			// display: block;
		}
	}
	
	@media(max-width: 736px) {
		.info-block {
			strong {
				display: block;
			}
		}
	}
	
	hr.divider {
		// background: $accent-a;
		margin: 5em 0 2em 0;
		padding: 0 !important;
		width: 100%;
		@include border-radius(3px);
	}

	ul {
		li {
			strong {
				width: 150px;
				display: inline-block;
			}
		}
	}
	
	article#main>header {
		padding: 1em;
	}
	footer#footer {
		padding: 0.5em;
	}

	article#main footer#footer {
		padding-bottom: 5em !important;
	}
}

@media(max-width: 736px) {
	.row>* {
			padding: 0 0 0 0.75em;
	}
	body #main h2 {
		font-size: 1.6em;
	}
	body table {
		// margin: 0;
	}
	input[name="save"] {
		background: rgba($dark-gray,0.9) !important;
		position: fixed;
		bottom: 0;
		border-radius: 4px 0 0 0;
		// height: 1em;
		font-size: 1em !important;
		width: 30% !important;
		right: 0;
	}
}

// Table filtering / notifications
// Archive Button
#archive {
		display: none;
}
#reveal {
	margin-bottom: $pad;
}
#reveal:target #archive {
		display: inline-block;
}
#reveal:target #current {
	display: none;
}
.archive-reveal {
	.fa {
		color: $accent;
	}
	color: $accent;
}

@media(max-width: 736px) {
	table th .fa {
		display: none;
	}
}

table#home tr {
	th, td {
		padding: 0.3em;
	}
	&.reply {
		-webkit-animation-name: pulse;
		-webkit-animation-duration: 2s;
		-webkit-animation-timing-function: ease-in-out;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-direction: alternate;
		-webkit-animation-delay: 0;
		-webkit-animation-fill-mode: none;
	}
	&.queued.show,
	&.reply.show {
		display: none;
	}
	&.ended, &.unavailable {
		display: none;
		&.show {
			display: table-row;
		}
	}
}
table tfoot {
	border-top: none;
}
table tbody tr:nth-child(2n+1) {
		background-color: transparent;
		border-top: 1px solid #EEEEEE;
		border-bottom: 1px solid #EEEEEE;
}

.pulse {
	-webkit-animation-name: text_pulse;
	-webkit-animation-duration: 2s;
	-webkit-animation-timing-function: ease-in-out;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	-webkit-animation-delay: 0;
	-webkit-animation-fill-mode: none;
}

@keyframes text_pulse {
	0% {
		-webkit-color: transparent;
		color: transparent;
	}
	50% {
		-webkit-color: $orange;
		color: $orange;
	}
	100% {
		-webkit-color: transparent;
		color: transparent;
	}
}

@keyframes pulse {
	0% {
		background: rgba($orange, 0.2);
	}
	50% {
		background: #FFF;
	}
	100% {
		background: rgba($orange, 0.2);
	}
}
@media(min-width: 1440px) {
	body table td:nth-of-type(2),
	body table th:nth-of-type(2) {
			padding-left: 0.5em;
	}
}
@media(max-width: 1020px) {
	body {
		table.mobile {
			th, td {
				&.event_id,
				// &.event_date,
				// &.event_payment,
				&.event_schedule,
				// &.event_location,
				&.event_venue,
				&.event_type,
				&.event_event {
				// &.event_invoice,
				// &.event_availability {
					display: none;
				}
			}
		}
	}
}
@media(max-width: 736px) {
	body {
		article#main>section.wrapper>.inner {
			padding: 0.5em;
		}
		.status_, .status_0, .status_1, .status_2, .status_3, .status_queued {
			padding: 0;
		}
	}
}

// Global
#payment-total {
	text-align: right;
	span {
		// color: $accent;
	}
}
@media(max-width: 480px) {
	#payment-total {
		text-align: left;
		h2 {
			font-size: 1.2em !important;
		}
	}
}

nav#menu {
	background: $dark;
	color: $light;
	font-size: 1.1em;
	padding: 1em 2em 0 2em;
	ul {
		li {
			a > i.fa {
				color: $light;
				margin-right: 0.5em;
			}
			&#logout {
				color: $accent;
			}
			&:first-of-type {
				color: $accent;
			}
		}
	}
}

@media(max-width: 1050px) {
	body table td:nth-of-type(2) {
		// padding-left: 2em;
	}
}

div#controls {
	position: fixed;
	padding-left: 1em;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 0.5em;
	a:before {
		color: $accent;
	}
}

@media(max-width: 1150px) {
	div#controls {
		display: none;
	}
}

#header>a+a[href="#menu"]:last-child {
		border-left: solid 1px rgba($light, 0.2);
}

header#header {
	background: $dark;
		height: 3.25em !important;
		line-height: 3.25em;
		z-index: 1;
	h1 {
		left: 0.6em;
		font-size: 1.25em;
		a {
			color: white;
		}
		span {
			display: inline;
		}
	}
	a[href="#menu"]:before{
		color: $light;
	}
	>.inner {
		text-align: left;
		padding: 0 $gutter+$gutter;
	} 
}

@media(max-width: 736px) {
	header#header {
		height: 2.5em !important;
		line-height: 2.5em;
		h1 {
			left: 0.2em;
		}
	}
	// article#main>header p {
	//  display: none;
	// }
	#event-header span {
		display: none;
	}
}

article#main {
	> header {
		background-image: none;
			background: $secondary;
			text-align: left;
			h2 {
				font-size: 1.5em;
			margin: 0;
			text-transform: uppercase;
		}
		p {
			color: $dark;
			letter-spacing: $spacing;
			margin: 0;
		}
	}

	> section.wrapper {
		padding: 1em 0 0 0;
		width: 100%;
		margin: auto;
		>.inner {
			max-width: 100%;
			padding: 0 $gutter;
		}
	}
}

footer#footer {
	background: $secondary;
		bottom: 0;
		right: 0;
	.copyright {
		text-align: left;
		margin: 0;
		font-size: 0.85em;
		p {
			color: $dark;
			margin: 0;
		}
	}
}

@media(max-width: 1070px) {
	footer#footer {
		bottom: 0;
		left: 0;
		z-index: 1;
	}
}

@media screen and (max-width: 1680px) {
	body, input, select, textarea {
		font-size: 0.9em;
	}
}

// Pagination
ul.pagination {
		display: block;
	list-style: none;
	padding: 0;
	color: $light;
	li {
		background: $dark;
		display: inline;
		padding: 0.5em;
		&.active {
			text-decoration: underline;
		}
		a {
			color: $light;
		}
		&:first-of-type{
			background: $dark-gray;
			border-radius: 3px 0 0 3px;
		}
		&:last-of-type{
			background: $dark-gray;
			border-radius: 0 3px 3px 0;
		}
	}
}

// DataTables
.table {
	.dataTables_length {
		select {
			width: auto;
			display: inline;
		}
	}
	input {
		color: $dark !important;
	}
}

// Datepicker / Plugin
div#ui-datepicker-div {
	background: $dark;
	font-size: 0.8em;
	width: 350px;
	max-width: 350px;
	.ui-datepicker-today a {
		color: $accent-b;
		font-weight: bold;
		text-decoration: underline;
	}
	.ui-datepicker-title {
		font-weight: bold;
		padding: 0.2em $gutter;
		background: $accent-b;
	}
	.ui-datepicker-prev.ui-corner-all,
	.ui-datepicker-next.ui-corner-all {
		padding: 0.4em;
		width: 50%;
		display: inline-block;
			cursor: pointer;
	}
	.ui-datepicker-buttonpane {
			padding: $gutter;
			button.ui-datepicker-close {
				margin-left: $gutter;
			}
	}

	table {
		margin: 0;
		th, td {
		}
		&.ui-datepicker-calendar {
			font-size: 12px;
			th {
					color: #FFFFFF;
			}
			td {
				padding: 0 0.75em;
			}
		}
	}
}

@media (max-width: 480px) {
	input[type="submit"], input[type="reset"], input[type="button"], button, .button {
		padding: $pad;
	}
}

// Login  Screen
.alert {
	color: $light;
}
body#login {
	background: url('/images/login-bg.jpg') no-repeat top;
}
#login {
	#action {
		@include border-radius(5px);
		background: rgba(0,0,0,0.9);
		max-width: 500px;
		padding: $pad * 2;
		margin: auto;

		h1 {  
			font-weight: bold;
			color: $light;
			margin-bottom: $pad;
		}
		input {
			font-size: 1.2em;
		}
	}
}

@media(max-width: 736px) {
	body#login {
		background: black;
		#action {
			h1 {
				margin: 0;
			}
			p {
				line-height: 1.2em;
			}
			position: absolute;
			bottom: 0;
			top: 0;
			left: 0;
				right: 0;
				height: 100%;
		}
	}
}

//// Error Handling
.error_block {
	.error_input {
		border-color: $red;
	}
	.error_message {
		strong {
			color: $red;
		}
	}
}


//// Pages 
@media(max-width: 736px) {
	#venues iframe {
		height: 400px;
	}
}

// Invoices

.invoice {
	padding: $pad;
	border-radius: 3px;
	border: 3px solid $accent-b;
	width: 980px;
	min-height: 1080px;
	margin: 2em 0;

	.table {
		th, td {
			width: auto;
			background: none;
		}
		td:nth-of-type(1) {
			position:relative;
			text-align: left;
			color: $dark-gray;
		}
		th:nth-of-type(3),
		th:nth-of-type(4) {
			width: 100px;
		}
	}
}

// Add Event
form {
	.wedding_extras_options  {
		text-align: right;
		label {
			color: $accent !important;
			font-weight: bold;
		}
	}
}

// All Events
body {
	#booking_total, #staff_total {
		h3 {
			color: $accent;
			font-size: 2em;
			font-weight: bold;
			margin: 0;
		}
	}
}
body table#queued_staff td:nth-of-type(1) {
	width: 150px;
	text-align: left;
}
div#event-staff {
	display: none;
}
a.delete-queued-staff, a.delete-staff {
	color: $light;
	display: inline-block;
	background: $red;
	padding: 0 $pad;
	cursor: pointer;
	@include border-radius(3px);
}
.additional-staff {
	// padding: $pad 0;
}
.queued-staff {
	font-size: 1.1em;
	display: inline;
}

@media(min-width: 736px) {
	#event-staff hr {
		display: none;
	}
}

@media(max-width: 736px) {
	.queued-staff {
		display: block;
		margin: 0.5em 0;
	}
	#event-staff {
			width: 100%;
	}
}

// Details
#event_details {
	h2 {
		i {
			color: $dark-gray;
		}
		color: $accent !important;
	}
	@media(max-width: 736px) {
		.event_controls_consultant, .event_controls_user {
			li {
				width: 33.33%;
				float: left;
				display: inline;
				padding: 0 1em 0 0;
			}
		}
		.event_controls_user {
			li {
				width: 50%;
			}
		}
	}
	.event_details_highlight {
		background: #FFFFFF !important;
		padding: 0.5em !important;
		strong {
			width: 140px !important;
		}
	}

}

// Autofill
input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 1000px $light inset !important;
}
.none {
	display: none;
}

@media print{
	#init-load, #header, #controls, #print-invoice, #payment-total {
		display: none;
	}
	.\35 u, .\37 u{
		width: 100%;
	}
}

/**
 * Animations
 */
