#init-load {
	font-size: 1.5em;
	color: #FFFFFF;
	display: none;
	background: rgba(0,0,0,0.9);
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 99;

	#modal {
		position: fixed;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		text-align: center;
		width: 80%;
		line-height: 1.2em;
		img {
			width: 100px;
		}
		#phone {
			-webkit-animation-name: rotate; 
			-webkit-animation-duration: 4s; 
			-webkit-animation-iteration-count: infinite;
			-webkit-transition-timing-function: linear;
			-webkit-animation-fill-mode: forwards;
			-webkit-animation-delay: 0;
		}
	}
}

@media(min-width: 737px) {
	#init-load {
		display: none !important;
	}
}

@keyframes rotate {
  0% { -webkit-transform: rotate(0deg); }
  5% { -webkit-transform: rotate(90deg); }
  10% { -webkit-transform: rotate(90deg); }
  20% { -webkit-transform: rotate(90deg); }  
  30% { -webkit-transform: rotate(90deg); }
  40% { -webkit-transform: rotate(0deg); }
  50% { -webkit-transform: rotate(0deg); }
  60% { -webkit-transform: rotate(0deg); }
  70% { -webkit-transform: rotate(0deg); }
  80% { -webkit-transform: rotate(0deg); }
  90% { -webkit-transform: rotate(0deg); }  
  100% { -webkit-transform: rotate(0deg); }
}